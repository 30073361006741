import { BehaviorSubject, Observable } from "rxjs";

export abstract class NpUserLoginService {

    protected _dataBs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public get dataObs(): Observable<any> {
        return this._dataBs.asObservable();
    }

    abstract isInitialized(): boolean;
    abstract initialize(): Promise<boolean>;
    abstract isLoggedIn(): boolean;
    abstract logout(): void;
    abstract getLoginData(): any;
    abstract getAuthorities(): string[] | null;

    /**
     * 인증정보를 초기화 한다.
     */
    abstract clear(): void;
}
